import { isDevMode, assertInInjectionContext, inject } from '@angular/core';
import { type CCASequenceStep } from '../sequence';
import { SequenceStore } from '../sequence-store';
import { type CompleteStepValue } from '@cca-infra/sequence-management/v1';

export type previousStepDataOptions<T> = {
  defaultValue?: T;
};

// Function Overloads
export function previousStepData<T>(
  property: string,
  options: { step?: CCASequenceStep; defaultValue: T },
): T;
export function previousStepData<T>(
  property: string,
  options?: { step?: CCASequenceStep; defaultValue?: T },
): T | null;

// Function Implementation
export function previousStepData<T>(
  property: string,
  options?: previousStepDataOptions<T>,
): T | null {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isDevMode() && assertInInjectionContext(previousStepData);
  const store = inject(SequenceStore);
  const step = store.currentActiveStep();

  if (!step) {
    throw new Error('No active step found and no step provided in options.');
  }

  const completeValues = step?.completeValues ?? [];
  if (!completeValues.length) {
    return options?.defaultValue ?? null;
  }

  return (
    (completeValues.find(
      (value): value is CompleteStepValue => value.key === property,
    )?.value as T) ?? null
  );
}
