import { assertInInjectionContext, inject, isDevMode } from '@angular/core';
import { SequenceStore } from '../sequence-store';
import { SequenceComponent } from '../public_api';

export type sequenceMetaDataOptions<T> = {
  defaultValue?: T;
  parse?: boolean;
};

// Function Overloads

export function sequenceMetaData<T = string>(property: string): T | null;
export function sequenceMetaData<T = string>(
  property: string,
  defaultValue: T,
): T;

// Function Implementation
export function sequenceMetaData<T = string>(
  property: string,
  defaultValue?: T,
): T | null {
  if (isDevMode()) {
    // check if we are a injectionContext
    assertInInjectionContext(sequenceMetaData);

    if (
      !inject(SequenceComponent, {
        optional: true,
      })
    ) {
      console.warn(
        `sequenceMetaData for property "${property}" should be called in a SequenceStep, using outside a sequence or within the sequence container component will not work correctly`,
      );
    }
  }

  const store = inject(SequenceStore);
  const step = store.currentActiveStep();

  const activeStepMetaData = step?.metaData ?? [];
  if (!activeStepMetaData.length) {
    return defaultValue ?? null;
  }

  const rawMetaData = activeStepMetaData.find(
    (value) => value.identifier === property,
  )?.value;

  // if rawMetaData is null/undefined we will print a warning and return the defaultValue or null
  if (!rawMetaData) {
    if (isDevMode()) {
      console.warn(
        `Metadata for property "${property}" was not found or is null/undefined.`,
      );
    }

    return defaultValue ?? null;
  }

  // Try parse the rawMetaData, include situations for upperCase True/False/Null ( which is not valid JSON )
  try {
    if (['False', 'True', 'Null'].includes(rawMetaData)) {
      return JSON.parse(rawMetaData.toLowerCase());
    }

    // Parse
    return JSON.parse(rawMetaData);
  } catch {
    // if we get a error its likely because the RawMetaData does not contain JSON and the result is a string
    // example would be 'EUR' as metaData
    return rawMetaData as T;
  }
}
