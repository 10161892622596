<ng-container *transloco="let t; read: 'sequence.confirmChangesDialog'">
  <div class="dialog-container">
    <header>
      <h2>{{ t('title') }}</h2>
      <button [mat-dialog-close]="null" class="dialog-close-button">
        <cca-icon icon="xmark"></cca-icon>
      </button>
    </header>

    <main>
      <p>
        {{ t('paragraph1') }}
        <span class="critical-text-light">{{ t('importantText') }}</span>
        {{ t('paragraph2') }}
      </p>
    </main>

    <footer>
      <button mat-stroked-button color="accent" [mat-dialog-close]="false">
        {{ t('discardBtn') }}
      </button>
      <button mat-flat-button color="primary" [mat-dialog-close]="true">
        {{ t('continueBtn') }}
      </button>
    </footer>
  </div>
</ng-container>
