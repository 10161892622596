import { Injectable, inject } from '@angular/core';
import { type SequenceError, SequenceStore } from './sequence-store';
import { ActivatedRoute, Router } from '@angular/router';
import { type Observable, exhaustMap, switchMap, tap } from 'rxjs';
import { type CCASequence } from './sequence';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { SequenceDevService } from '@cca-infra/sequence-management/v1';
import { sequenceNameToken } from './sequence-name';

export interface SequenceDevToolState {
  stepNames: string[];
}

export const initialDevToolState: SequenceDevToolState = {
  stepNames: [],
};

@Injectable()
export class SequenceDevToolsStore<
  SequenceType extends CCASequence = CCASequence,
> extends ComponentStore<SequenceDevToolState> {
  store = inject(SequenceStore);
  activatedRoute = inject(ActivatedRoute);
  devService = inject(SequenceDevService);
  router = inject(Router);
  sequenceName = inject(sequenceNameToken);

  constructor() {
    super(initialDevToolState);
  }

  readonly stepNames = this.selectSignal((s) => s.stepNames);

  navigate = this.effect((origin$: Observable<string>) =>
    origin$.pipe(
      tap(() => {
        this.store['setLoadingSequenceInProgress'](true);
        this.store['setError'](null);
      }),
      exhaustMap((stepName: string) => {
        return this.devService
          .navigate<SequenceType>(
            this.sequenceName() as string,
            stepName,
            this.store.entityId,
          )
          .pipe(
            tapResponse({
              next: (sequence) => {
                this.store['setSequenceState'](sequence);
              },
              error: (err) => {
                this.store['setError'](err as SequenceError);
              },
              finalize: () => {
                this.store['setLoadingSequenceInProgress'](false);
              },
            }),
          );
      }),
    ),
  );

  getStepNames = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      switchMap(() => {
        return this.devService
          .getAllStepNames(this.sequenceName() as string)
          .pipe(
            tapResponse(
              (stepNames) => {
                this.patchState({
                  stepNames: stepNames,
                });
              },
              (err) => {
                console.error(err);
              },
            ),
          );
      }),
    ),
  );
}
