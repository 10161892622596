import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  type OnDestroy,
  type OnInit,
  type TemplateRef,
  inject,
  isDevMode,
  signal,
  viewChild,
} from '@angular/core';
import { JsonPipe } from '@angular/common';
import { SequenceStore } from '../../sequence-store';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  type MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { SequenceDevToolsStore } from '../../dev-tool.store';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatBottomSheet,
  MatBottomSheetModule,
  type MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@cca/ui';
import { MatListModule } from '@angular/material/list';
import { AutoCompleteFieldComponent } from '@cca-common/forms/autocomplete-field';
import {
  type Observable,
  Subject,
  catchError,
  concat,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import {
  type SequenceUser,
  SequenceUserStateService,
} from '@cca-infra/sequence-management/v1';
import { type CCASequence } from '../../sequence';
import { PushPipe } from '@ngrx/component';
import { sequenceNameToken } from '../../sequence-name';

@Component({
  selector: 'cca-sequence-dev-tool',
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatListModule,
    IconComponent,
    JsonPipe,
    AutoCompleteFieldComponent,
    PushPipe,
  ],
  templateUrl: './dev-tool.component.html',
  styleUrls: ['./dev-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceDevToolComponent implements OnInit, OnDestroy {
  cdr = inject(ChangeDetectorRef);
  store = inject(SequenceStore);
  devStore = inject(SequenceDevToolsStore);
  matBottomSheet = inject(MatBottomSheet);
  service = inject(SequenceUserStateService);
  sequenceName = inject(sequenceNameToken);

  readonly sheetTemplate =
    viewChild.required<TemplateRef<unknown>>('sheetContents');
  bottomSheetRef: MatBottomSheetRef | null = null;
  users$: Observable<SequenceUser[]>;
  typeahead$ = new Subject<string>();
  filteredUsers$: Observable<SequenceUser[]>;

  showNavigation = signal(isDevMode());

  constructor() {
    this.users$ = this.service
      .getUsers(this.sequenceName() as string, this.store.entityId)
      .pipe(shareReplay(1));

    this.filteredUsers$ = concat(
      of([]), // default items
      this.typeahead$.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((term) => {
          const trimmedTerm = term?.trim()?.toLowerCase() ?? '';
          return this.users$.pipe(
            catchError(() => of([])),
            map((users) =>
              users.filter((user) =>
                user.userName.trim().toLowerCase().includes(trimmedTerm),
              ),
            ),
          );
        }),
      ),
    );
  }

  openSheet(): void {
    // preload the users
    this.users$.subscribe();
    this.bottomSheetRef = this.matBottomSheet.open(this.sheetTemplate(), {
      closeOnNavigation: true,
    });
    this.bottomSheetRef
      .afterDismissed()
      .subscribe(() => (this.bottomSheetRef = null));
  }

  ngOnInit(): void {
    if (this.showNavigation()) {
      this.devStore.getStepNames();
    }
  }

  stepSelectionChanged(event: MatSelectChange) {
    if (!event?.value) {
      return;
    }

    const stepName = event.value;
    this.devStore.navigate(stepName);
  }

  copyUserState(user: SequenceUser) {
    this.service
      .copyState({
        userId: user.userId,
        sequenceName: this.sequenceName() as string,
        entityId: this.store.entityId,
      })
      .subscribe((sequenceState) => {
        this.store['setSequenceState'](sequenceState as unknown as CCASequence);
      });
  }

  logState() {
    console.log(this.store.state());
  }

  logStep() {
    console.log(this.store.currentActiveStep());
  }

  logCurrentData() {
    console.log(this.store['currentStepData']());
  }

  logExpectedStepKeys() {
    const stepId = this.store.currentActiveStep()?.stepId;
    if (!stepId) {
      console.log(`could not log expected step keys, stepId is null`);
      return;
    }

    this.devStore.devService
      .getExpectedStepValueKeys(stepId)
      .subscribe((log) => console.log(log));
  }

  ngOnDestroy(): void {
    this.bottomSheetRef?.dismiss();
  }
}
