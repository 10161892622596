import { type HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { type id } from '@cca-infra/common';
import {
  LogisticServiceProviderType,
  SequenceUserStateService,
} from '@cca-infra/sequence-management/v1';
import { filterNull } from '@cca-common/cdk';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  EMPTY,
  type Observable,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { SequenceConfirmDialogComponent } from './components/confirm-dialog';
import { unwrap, wrap } from './wrap';
import {
  type CCASequence,
  type CCASequenceStep,
  type SequenceStepValue,
} from './sequence';
import { type SequenceStoreStepValue } from './sequence-store-step-value';
import { sequenceNameToken } from './sequence-name';
import { HeaderService } from '@cca-common/page-title';
import { DOCUMENT } from '@angular/common';

export interface SequenceState<SequenceType extends CCASequence> {
  sequence: null | SequenceType;
  currentStepData: SequenceStoreStepValue[] | null;
  loadingSequenceInProgress: boolean;
  completingStepInProgress: boolean;
  error: SequenceError;
  invalid: boolean;
  isLastIteration: boolean;
}

export const initialSequenceState = {
  sequence: null,
  currentStepData: null,
  currentStepDataChanged: false,
  loadingSequenceInProgress: false,
  completingStepInProgress: false,
  invalid: false,
  isLastIteration: false,
  error: null,
};

export type SequenceError = null | string;

@Injectable()
export class SequenceStore<
  SequenceType extends CCASequence = CCASequence,
> extends ComponentStore<SequenceState<SequenceType>> {
  private readonly sequenceService = inject(SequenceUserStateService);
  private readonly dialog = inject(MatDialog);
  private sequenceNameSignal = inject(sequenceNameToken);
  private document = inject(DOCUMENT);
  private headerService = inject(HeaderService);

  private _entityId: id | undefined;
  set entityId(id: id | undefined) {
    this._entityId = id;
  }

  get entityId() {
    return this._entityId as string;
  }

  // TODO: remove sequenceName and properties, its currently unused by store and just exists for several outside of sequence accessing
  private _sequenceName: string | null = null;
  set sequenceName(name: string) {
    this._sequenceName = name;
  }

  get sequenceName() {
    if (!this._sequenceName) {
      throw new Error('SequenceStore requires sequenceName to be set');
    }

    return this._sequenceName;
  }

  constructor() {
    super(initialSequenceState);
  }

  /**
   * Selectors
   */
  readonly invalid = this.selectSignal((state) => {
    return state.invalid;
  });

  readonly isLastIteration = this.selectSignal((state) => {
    return state.isLastIteration;
  });

  readonly loading = this.selectSignal((state) => {
    return (
      !!state.loadingSequenceInProgress || !!state.completingStepInProgress
    );
  });

  readonly error = this.selectSignal((state) => {
    return state.error;
  });

  readonly sequence = this.selectSignal((state) => {
    return state.sequence;
  });

  readonly sequenceId = this.selectSignal((state) => {
    return state.sequence?.sequenceId ?? null;
  });

  readonly sequenceStateId = this.selectSignal((state) => {
    return state.sequence?.sequenceStateId ?? null;
  });

  readonly currentActiveStep = this.selectSignal((state) => {
    const activeStep =
      state.sequence?.navigatedStep ??
      state.sequence?.nextUncompletedStep ??
      null;

    if (activeStep) {
      activeStep.completeValues = unwrap(activeStep.completeValues);
    }
    return activeStep;
  });

  readonly previousStep = this.selectSignal((state) => {
    return state.sequence?.previousStep ?? null;
  });

  readonly nextStep = this.selectSignal((state) => {
    return state.sequence?.nextStep ?? null;
  });

  readonly nextUncompletedStep = this.selectSignal(
    (state) => state.sequence?.nextUncompletedStep ?? null,
  );

  readonly completedSteps = this.selectSignal((state) => {
    return state.sequence?.completedSteps ?? [];
  });

  readonly repeatableSteps = this.selectSignal((state) => {
    return state.sequence?.repeatableSteps ?? [];
  });

  readonly summaryValues = this.selectSignal((state) => {
    return state.sequence?.summaryValues ?? [];
  });

  readonly currentStepIsPreviouslyCompleted = this.selectSignal(
    this.currentActiveStep,
    this.completedSteps,
    (activeStep, completedSteps) => {
      return !!completedSteps.find(
        (step) =>
          step.stepId === activeStep?.stepId &&
          step.stepIteration === activeStep.stepIteration,
      );
    },
  );

  readonly currentStepDataChanged = this.selectSignal(
    (state) => state.currentStepData !== null,
  );

  protected readonly currentStepData = this.selectSignal((state) => {
    return state.currentStepData;
  });

  readonly canContinue = this.selectSignal(
    this.currentStepData,
    this.currentStepIsPreviouslyCompleted,
    this.currentActiveStep,
    this.invalid,
    (currentStepData, previouslyCompleted, activeStep, invalid) => {
      if (invalid) return false;

      if (activeStep?.stepIsOptional) {
        return true;
      }

      // if not completed before, our stepData should be an array of at least one value
      if (!previouslyCompleted) {
        const canContinue =
          !!(currentStepData && currentStepData.length > 0) ||
          !!this.currentActiveStep()?.stepIsOptional;
        if (!canContinue) {
          this.scrollToFirstInvalidControl();
        }
        return canContinue;
      }
      // else we can allow it currentStepData is null

      return currentStepData === null || currentStepData.length > 0;
    },
  );

  /**
   * Updaters
   */
  protected readonly setLoadingSequenceInProgress = this.updater(
    (
      state: SequenceState<SequenceType>,
      loadingSequenceInProgress: boolean,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        loadingSequenceInProgress: loadingSequenceInProgress,
      };
    },
  );

  protected readonly setCompletingStepInProgress = this.updater(
    (
      state: SequenceState<SequenceType>,
      completingStepInProgress: boolean,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        completingStepInProgress: completingStepInProgress,
      };
    },
  );

  protected readonly setError = this.updater(
    (
      state: SequenceState<SequenceType>,
      error: SequenceError,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        error: error,
      };
    },
  );

  protected readonly setSequenceState = this.updater(
    (
      state: SequenceState<SequenceType>,
      sequence: SequenceType | null,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        sequence: sequence,
        currentStepData: null,
        invalid: false,
        isLastIteration: false,
      };
    },
  );

  readonly setInvalid = this.updater(
    (
      state: SequenceState<SequenceType>,
      invalid: boolean,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        invalid: invalid,
      };
    },
  );

  readonly setLastIteration = this.updater(
    (
      state: SequenceState<SequenceType>,
      isLastIteration: boolean | null,
    ): SequenceState<SequenceType> => {
      return {
        ...state,
        isLastIteration: !!isLastIteration,
      };
    },
  );

  readonly setCurrentStepData = (
    stepKey: string | undefined,
    currentStepData: (SequenceStoreStepValue | null)[] | null,
    submitImmediately = false,
  ): void => {
    const currentActiveStepKey = this.currentActiveStep()?.stepKey;
    if (stepKey !== currentActiveStepKey) {
      // when in development mode, throw a error so a developer can check it out and possibly fix
      if (isDevMode()) {
        throw Error(
          `SequenceStore: Writing StepData of step: ${stepKey} while current step is ${currentActiveStepKey}`,
        );
      }

      // if we're not in devMode, just gracefully ignore and do not update
      return;
    }

    this.patchState((state) => {
      return {
        ...state,
        currentStepData: currentStepData?.filter(filterNull) ?? null,
      };
    });

    if (submitImmediately) {
      this.continueToNextStep();
    }
  };

  /**
   * Effects
   */
  getCurrentSequenceState = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => {
        this.setLoadingSequenceInProgress(true);
        this.setError(null);
      }),
      exhaustMap(() => {
        return this.sequenceService
          .getState<SequenceType>(
            this.sequenceNameSignal() as string,
            this.entityId,
          )
          .pipe(
            tapResponse({
              next: (sequence) => {
                this.setSequenceState(sequence);
                this.addHeaderBadge();
              },
              error: (err) => {
                this.setError(err as SequenceError);
              },
              finalize: () => {
                this.setLoadingSequenceInProgress(false);
              },
            }),
          );
      }),
    ),
  );

  continueToNextStep = this.effect(
    (
      origin$: Observable<{
        repeatableStepId?: id;
        forceReset?: boolean;
      } | void>,
    ) =>
      origin$.pipe(
        filter(() => this.canContinue()),
        map((data) => data || null),
        exhaustMap(
          (
            data: {
              repeatableStepId?: id;
              forceReset?: boolean;
            } | null,
          ) => {
            const currentStep = this.currentActiveStep();
            if (!currentStep) {
              return EMPTY;
            }
            // if current step is not previously completed we can just do a submit
            if (!this.currentStepIsPreviouslyCompleted()) {
              return this.submitStep(
                currentStep.stepId,
                data?.repeatableStepId,
              );
            }
            // get next stepId if possible
            const stepId = this.nextStep()?.stepId ?? null;
            if (!this.currentStepDataChanged() && !data?.repeatableStepId) {
              // stepId could be null since we don't have a id of a not yet previously completed step
              // could happen if we pressed back once
              return this.navigateToStep(stepId);
            }
            if (!currentStep.updateStepClearsFutureSteps) {
              return this.submitStep(
                currentStep.stepId,
                data?.repeatableStepId,
              );
            }
            if (data?.forceReset != null) {
              if (data?.forceReset) {
                if (data?.repeatableStepId)
                  return this.submitStep(
                    currentStep?.stepId,
                    data?.repeatableStepId,
                  );
                else return this.submitStep(currentStep?.stepId);
              } else {
                return this.navigateToStep(stepId);
              }
            } else {
              // step is previously completed and data has changed
              return this.openSequenceChangesDialog(
                currentStep,
                stepId,
                data?.repeatableStepId,
              );
            }
          },
        ),
      ),
  );

  private submitStep = (stepId: id, repeatableStepId?: id | null) => {
    const values = this.currentStepData();

    // When step is not optional and value is falsy we should not continue
    if (!values && !this.currentActiveStep()?.stepIsOptional) {
      return EMPTY;
    }

    const currentStep = this.currentActiveStep();
    const stepIteration = currentStep?.stepIteration || 0;

    this.setError(null);
    this.setCompletingStepInProgress(true);

    if (repeatableStepId) {
      return this.sequenceService
        .completeAndRepeatStep<SequenceType>({
          sequenceName: this.sequenceNameSignal() as string,
          stepId: stepId,
          stepIteration: stepIteration,
          repeatedStepId: repeatableStepId,
          isLastIteration: this.isLastIteration(),
          values: (wrap(values) as SequenceStepValue[]) ?? [],
          entityId: this.entityId,
        })
        .pipe(
          tapResponse({
            next: (sequence) => {
              this.setSequenceState(sequence);
              this.addHeaderBadge();
            },
            error: (httpError: HttpErrorResponse) => {
              this.setError(httpError.error as SequenceError);
            },
            finalize: () => {
              this.setCompletingStepInProgress(false);
            },
          }),
        );
    }

    const nextStep = this.nextStep();

    return this.sequenceService
      .completeStep<SequenceType>({
        sequenceName: this.sequenceNameSignal() as string,
        stepId: stepId,
        stepIteration: stepIteration,
        isLastIteration: this.isLastIteration(),
        values: (wrap(values) as SequenceStepValue[]) ?? [],
        entityId: this.entityId,
        navigatedStepId: nextStep?.stepId,
        navigatedStepIteration: nextStep?.stepIteration,
      })
      .pipe(
        tapResponse({
          next: (sequence) => {
            this.setSequenceState(sequence);
            this.addHeaderBadge();
          },
          error: (httpError: HttpErrorResponse) => {
            this.setError(httpError.error as SequenceError);
          },
          finalize: () => {
            this.setCompletingStepInProgress(false);
          },
        }),
      );
  };

  private navigationStepId = (stepId: id | null) => {
    if (stepId) {
      return stepId;
    }

    const nextUncompletedStep = this.nextUncompletedStep();
    if (nextUncompletedStep) {
      return nextUncompletedStep.stepId;
    }

    return null;
  };

  private navigateToStep(stepId: id | null) {
    const resolvedStepId = this.navigationStepId(stepId);
    if (!resolvedStepId) {
      return EMPTY;
    }

    const currentStep = this.nextStep();
    const currentStepId = currentStep?.stepId ?? null;
    const stepIteration = currentStep?.stepIteration || 0;

    this.setError(null);
    this.setCompletingStepInProgress(true);
    return this.sequenceService
      .navigate<SequenceType>({
        sequenceName: this.sequenceNameSignal() as string,
        stepId: resolvedStepId,
        stepIteration: stepIteration,
        currentStepId: currentStepId,
        entityId: this.entityId,
      })
      .pipe(
        tapResponse({
          next: (sequence) => {
            this.setSequenceState(sequence);
          },
          error: (httpError: HttpErrorResponse) => {
            this.setError(httpError.error as SequenceError);
          },
          finalize: () => {
            this.setCompletingStepInProgress(false);
          },
        }),
      );
  }

  private openSequenceChangesDialog(
    currentStep: CCASequenceStep,
    stepId: id | null,
    repeatableStepId: id | undefined,
  ): Observable<SequenceType> {
    // step is previously completed and data has changed
    return this.dialog
      .open(SequenceConfirmDialogComponent, {
        maxWidth: '38rem',
      })
      .afterClosed()
      .pipe(
        switchMap((result: null | boolean) => {
          if (typeof result !== 'boolean') {
            return EMPTY;
          }

          if (result) {
            if (repeatableStepId)
              return this.submitStep(currentStep?.stepId, repeatableStepId);
            else return this.submitStep(currentStep?.stepId);
          }

          return this.navigateToStep(stepId);
        }),
      );
  }

  resetSequence = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      tap(() => {
        this.setLoadingSequenceInProgress(true);
        this.setError(null);
        this.setSequenceState(null);
      }),
      exhaustMap(() => {
        return this.sequenceService
          .reset<SequenceType>(
            this.sequenceNameSignal() as string,
            this.entityId,
          )
          .pipe(
            tapResponse({
              next: (sequence) => {
                this.setSequenceState(sequence);
                this.addHeaderBadge();
              },
              error: (err) => {
                this.setError(err as SequenceError);
              },
              finalize: () => {
                this.setLoadingSequenceInProgress(false);
              },
            }),
          );
      }),
    ),
  );

  navigateBackTo = this.effect(
    (
      origin$: Observable<
        | id
        | {
            stepId: id;
            stepIteration: number;
          }
        | void
      >,
    ) =>
      origin$.pipe(
        switchMap(
          (
            data:
              | id
              | {
                  stepId: id;
                  stepIteration: number;
                }
              | void,
          ) => {
            const hasStepIteration = typeof data === 'object';
            let stepId = hasStepIteration ? data.stepId : data;

            if (!stepId) {
              const previousStep = this.previousStep();
              if (!previousStep) {
                return EMPTY;
              }
              stepId = previousStep.stepId;
            }

            const currentStep = this.previousStep();
            const currentStepId = currentStep?.stepId ?? null;
            const stepIteration = currentStep?.stepIteration || 0;

            return of({
              stepId,
              currentStepId,
              stepIteration: hasStepIteration
                ? data.stepIteration
                : stepIteration,
            });
          },
        ),
        tap(() => {
          this.setLoadingSequenceInProgress(true);
          this.setError(null);
        }),
        exhaustMap(({ stepId, currentStepId, stepIteration }) => {
          return this.sequenceService
            .navigate<SequenceType>({
              sequenceName: this.sequenceNameSignal() as string,
              stepId: stepId,
              stepIteration: stepIteration,
              currentStepId: currentStepId,
              entityId: this.entityId,
            })
            .pipe(
              tapResponse({
                next: (sequence) => {
                  this.setSequenceState(sequence);
                },
                error: (httpError: HttpErrorResponse) => {
                  this.setError(httpError.error as SequenceError);
                },
                finalize: () => {
                  this.setLoadingSequenceInProgress(false);
                },
              }),
            );
        }),
      ),
  );

  private scrollToFirstInvalidControl() {
    const invalidControls = this.document.getElementsByClassName(
      'ng-invalid ng-touched mat-mdc-form-field',
    );
    const firstInvalidControl = invalidControls[0];
    if (firstInvalidControl) {
      (firstInvalidControl as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
      (firstInvalidControl as HTMLElement).focus();
    }
  }

  private addHeaderBadge() {
    const logisticServiceProviderType = Number(
      this.summaryValues().find(
        (x) => x.summaryKey === 'LogisticServiceProviderType',
      )?.summaryValue,
    );

    if (
      logisticServiceProviderType ===
      LogisticServiceProviderType.ManagedTransport
    )
      this.headerService.set({
        title: this.headerService()?.title ?? null,
        badge: 'MT',
        flavor: 'highlight',
      });
    else
      this.headerService.set({
        title: this.headerService()?.title ?? null,
        badge: null,
        flavor: 'highlight',
      });
  }
}
